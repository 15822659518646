.why {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.why .center-img {
  max-height: 600px;
  width: auto;
  margin: 40px auto;
}

.content h2 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 80px;
}

.content h2 span {
  background: linear-gradient(to right, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.content .card {
  display: flex;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.why .card .card-icon {
  font-size: 35px;
  color: #fff;
  width: 70px;
  height: 70px;
  border-radius: 25%;
  background: linear-gradient(to top, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.why .card .card-body {
  display: flex;
  flex-direction: column; 
  padding: 10px 5px;
}

.why .card .card-title {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 23px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: start;
}

.why .card .card-text {
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: none;
  text-align: start;
}

/* Extra Small Devices (Mobile Phones) */
@media (max-width: 575.98px) {
  .content h2 {
    font-size: 48px;
    margin-bottom: 60px;
  }
  .why .center-img {
    max-height: 400px;
  }
  .why .card .card-title {
    font-size: 20px;
  }
  .why .card .card-text {
    font-size: 14px;
  }
}

/* Small Devices (Tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .content h2 {
    font-size: 64px;
    margin-bottom: 70px;
  }
  .why .center-img {
    max-height: 400px;
  }
  .why .card .card-title {
    font-size: 22px;
  }
  .why .card .card-text {
    font-size: 15px;
  }
}

/* Medium Devices (Laptops and Small Desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .content h2 {
    font-size: 72px;
    margin-bottom: 90px;
  }
  .why .center-img {
    max-height: 500px;
  }
  .why .card .card-title {
    font-size: 24px;
  }
  .why .card .card-text {
    font-size: 16px;
  }
}

/* Large Devices (Desktops and Large Screens) */
@media (max-width: 992px) {
  .content h2 {
    font-size: 80px;
    margin-bottom: 100px;
  }
}
