.team {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.team h1 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 80px;
  text-align: center;
}

.team h1 span {
  background: linear-gradient(to right, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.team .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 80px 0;
}

.team-descreption {
  flex: 1;
  padding: 20px;
}

.team-descreption p {
  font-size: 18px;
  font-weight: 500;
  color: #5f5f5f;
  line-height: 1.5;
  margin-right: 20px;
  text-align: start;
  font-style: italic;
}

.team-members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.team-member {
  flex: 0 0 calc(50% - 10px);
  display: flex;
  background-color: transparent;
  border-radius: 25px;
  margin-bottom: 20px;
  width: 100%;
  height: 350px;
  flex-direction: row;
}

.team-member-image {
  width: 50%;
  max-height: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.team-member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-member-details h3 {
  font-size: 60px;
  font-weight: 700;
  color: #111111;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  line-height: 1;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  margin-left: -120px;
}

.team-member-details p {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  line-height: 1.5;
  margin-left: 20px;
  text-align: start;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-links a {
  display: inline-block;
  color: #333;
  font-size: 24px;
  margin: 0 10px;
}

.carousel-root {
  overflow: hidden;
}

.carousel-container {
  overflow-x: hidden;
  position: relative;
  min-height: 300px;
}

.carousel-slide {
  flex: 0 0 calc(100% / 3);
  display: flex;
  justify-content: space-between;
}

.carousel-buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.carousel-button {
  font-size: 25px;
  background-color: #fff;
  color: #ebebeb;
  width: 40px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
}

.carousel-button-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 1; 
}

.carousel-slide:first-of-type.selected .carousel-button-prev {
  opacity: 0.5;
  cursor: not-allowed; 
}

.carousel-slide:last-of-type.selected .carousel-button-next {
  opacity: 0.5;
  cursor: not-allowed;
}

.carousel-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Media Queries */

/* Extra Small Devices (Mobile Phones) */
@media (max-width: 575.98px) {
  .team h1 {
    font-size: 50px; 
    margin-bottom: 60px;
  }

  .team-descreption p {
    font-size: 12px;
  }

  .team-members-container {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .team-member {
    flex: 0 0 50%;
    height: auto;
  }

  .team-member-details h3 {
    font-size: 15px;
    margin: 5px 0;
    text-align: center;
  }

  .team-member-image {
    border-radius: 15px;
  }

  .team-member-details {
    width: 50%;
  }

  .team-member-details p {
    font-size: 12px;
    margin-left: 10px;
  }

  .social-links {
    justify-content: space-around;
  }
  .social-links a {
    font-size: 10px;
    margin: 0 5px;
  }

  .carousel-button {
    width: 20px;
    height: 20px;
    font-size: 15px;
  }
}

/* Small Devices (Tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .team h1 {
    font-size: 60px;
    margin-bottom: 70px;
  }

  .team-descreption p {
    margin-right: 0;
    text-align: center;
  }

  .team-members-container {
    flex-direction: column;
  }

  .team-member {
    flex: 0 0 50%;
    height: auto;
  }

  .team-member-details h3 {
    font-size: 28px;
    margin: 10px 0;
    text-align: center;
  }
}
